import React, {useEffect, useState} from "react";
import {ApiClient} from "../services/api";
import {Auction} from "../global";
import {Button, Form, Modal, Table} from "react-bootstrap";
import {Loading} from "../components/Loading";

type NewBid = {
    amount: number,
    auctionId: string
}

export const AuctionHousePage = () => {

    const [auctions, setAuctions] = useState<Auction[]>()

    const [show, setShow] = useState(false);

    const [auction, setAuction] = useState<Auction>();
    
    const [bid, setBid] = useState<NewBid>();
    
    const openBidModal = (auction: Auction) => {
        
        setBid({
            amount: 0,
            auctionId: auction.id
        })
        
        setAuction(auction);
        
        setShow(true);
    }

    const handleChange = (e: any) => {
        setBid({
            ...bid!,
            [e.target.name]: e.target.value
        });
    }

    const MakeBid = async () => {
        const client = new ApiClient();
        
        await client.post('auction/bid', {
            amount: bid!.amount,
            auctionId: bid!.auctionId
        })
    }
    
    const handleClose = () => setShow(false);
    
    useEffect(() => {
        const fetch = async () => {
            const client = new ApiClient();

            const auctions = await client.get('auction');

            setAuctions(auctions )
        }
        
        fetch()
    }, []);
    
    
    if(!auctions) return <Loading/>
    
    return <>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Type</th>
                <th>Item</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {Object.entries(auctions).map(([key, auction]) => (
                <tr key={key}>
                    <td style={{width: '10%'}} className=''>{auction.item.type}</td>
                    <td style={{width: '10%'}} className=''>{auction.item.name}</td>
                   <td> 
                           <Button variant="primary" onClick={event => openBidModal(auction)}>
                            Launch demo modal
                        </Button>
                   </td>   
                </tr>
            ))}
            </tbody>
        </Table>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{auction?.item.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    
                    <input type="number" value={bid?.amount} onChange={handleChange} name="amount"/>
                    
                    <Button variant='primary' type='button' onClick={MakeBid}>Save</Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}


